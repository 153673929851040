export const config = {
  type: 'yaloop',
  webSite: 'https://web.yaloop.com',
  apiKey: "AIzaSyC2EmWrJdxlv8nRt1CYCUhamAyk36kQlW0",
  authDomain: "yaloop-telecommunications.firebaseapp.com",
  cloudFunctionsURL: "https://us-central1-yaloop-telecommunications.cloudfunctions.net/",
  projectId: "yaloop-telecommunications",
  storageBucket: "yaloop-telecommunications.appspot.com",
  messagingSenderId: "191753659435",
  appId: "1:191753659435:web:4bd183f48fe5887938b66e",
  measurementId: "G-QGZM8RDGRL",
  vapidKey: "BISIeo0VVe8D9WutfuSkRasnptsY3Efj_zXcQsBcjWpSi7lVlr2Sf_73_Rwh7VkoNrHYtxOzWN91Vz106hfHbJ0",
  stripeKey: "pk_live_51MvpMlJKS0zfyr0pzoi7sYXZyq6MGYlZMpfA9Be3xJq8YUsaCSalGmo2oyHXRJixFzJDzaThGpRBwfNCSwcs2CB6004QRLNGcU",
  agoraAppID: "64c0dbf869f94f6f907c7defbdd3a696",
  title: "Yaloop Web",
}

export const defaultTheme = {
  id: 'yaloopTheme',
  mainColor: '#5F9D3A',
  backgroundColor: '#f9f9f9',
  appName: 'Yaloop',
  appConsultantProfileTitle: 'Yaloop',
  appConsultantProfileDescription: 'Online Consultancy Platform',
  consultantTitle: 'consultant',
  employeeTitle: 'employee',
  giftButtonName: 'Gift A Session',
  menu: {
    dashboard: "Get Started &<br />Upcoming Appointments",
    history: "History",
    profile: "Profile",
    consultantsList: "Consultants"
  },
  bookingEnableName: 'schedule',
  logo: 'https://firebasestorage.googleapis.com/v0/b/yaloop-telecommunications.appspot.com/o/logos%2Fyaloop_logo.png?alt=media&token=9038ffa5-348e-40db-b9c6-c53defe0302e',
  universalLink: '', // db
  domainStart: "yalooptelecommunications.page.link"
}

export const teamMasters = null
